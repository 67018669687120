// Firebase
import { initializeApp } from "firebase/app";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCvGChRuxSS5xK6vS4PXGRwo5CgurKkWkM",
  authDomain: "krika-app-alistamiento.firebaseapp.com",
  projectId: "krika-app-alistamiento",
  storageBucket: "krika-app-alistamiento.appspot.com",
  messagingSenderId: "830489644223",
  appId: "1:830489644223:web:e3f6573fb0449d306e0a0a"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
