<template>
  <div class="container">
    <div class="row justify-content-center mt-5">
      <div class="body col-lg-9 p-3 p-md-5">
        <img src="/logo.svg" alt="" width="200" class="d-block mx-auto mb-3" />
        <h3 class="fw-bold mb-3">Id Orden</h3>
        <div class="input-group mb-3">
          <input
            type="number"
            class="form-control"
            placeholder="Escribe el id"
            v-model.number="id_order"
          />
          <button type="button" class="btn btn-dark" @click="getOrder()">
            <i class="bi bi-search"></i>
          </button>
        </div>

        <div class="text-center mt-4" v-if="orderData">
          <div class="row justify-content-center">
            <div class="col-10 col-md-3">
              <div
                v-if="
                  accepted == true ||
                  enlisting == true ||
                  incomplete == true ||
                  prepared == true ||
                  prepared_without_packing == true ||
                  prepared_packed == true ||
                  invoiced == true ||
                  on_way == true ||
                  delivered == true ||
                  finalized == true
                "
                class="position-relative mb-3 mb-md-0"
              >
                <picture class="colortrue rounded-circle">
                  <img src="../assets/icons/orden.png" alt="" />
                </picture>
                <h6 class="fw-bold texttrue pt-2 mt-4">Orden Aceptada</h6>
                <div class="line line-true"></div>
              </div>
              <div class="position-relative mb-3 mb-md-0" v-else>
                <picture class="colorfalse rounded-circle">
                  <img src="../assets/icons/orden.png" alt="" />
                </picture>
                <h6 class="fw-bold textfalse pt-2 mt-4">Orden Creada</h6>
                <div class="line line-false"></div>
              </div>
            </div>
            <div class="col-10 col-md-3">
              <div
                v-if="
                  enlisting == true ||
                  incomplete == true ||
                  prepared == true ||
                  prepared_without_packing == true ||
                  prepared_packed == true ||
                  invoiced == true ||
                  on_way == true ||
                  delivered == true ||
                  finalized == true
                "
                class="position-relative mb-3 mb-md-0"
              >
                <picture class="colortrue rounded-circle">
                  <img src="../assets/icons/pago.png" alt="" />
                </picture>
                <h6 class="fw-bold texttrue pt-2 mt-4">Orden Alistada</h6>
                <div class="line line-true"></div>
              </div>
              <div class="position-relative mb-3 mb-md-0" v-else>
                <picture class="colorfalse rounded-circle">
                  <img src="../assets/icons/pago.png" alt="" />
                </picture>
                <h6 class="fw-bold textfalse pt-2 mt-4">
                  Orden en Alistamiento
                </h6>
                <div class="line line-false"></div>
              </div>
            </div>
            <div class="col-10 col-md-3">
              <div
                v-if="
                  invoiced == true ||
                  on_way == true ||
                  delivered == true ||
                  finalized == true
                "
                class="position-relative mb-3 mb-md-0"
              >
                <picture class="colortrue rounded-circle">
                  <img src="../assets/icons/factura.png" alt="" />
                </picture>
                <h6 class="fw-bold texttrue pt-2 mt-4">Pedido Facturado</h6>
                <div class="line line-true"></div>
              </div>
              <div class="position-relative mb-3 mb-md-0" v-else>
                <picture class="colorfalse rounded-circle">
                  <img src="../assets/icons/factura.png" alt="" />
                </picture>
                <h6 class="fw-bold textfalse pt-2 mt-4">Pedido sin Facturar</h6>
                <div class="line line-false"></div>
              </div>
            </div>
            <div class="col-10 col-md-3">
              <div
                v-if="on_way == true || delivered == true || finalized == true"
                class="position-relative mb-3 mb-md-0"
              >
                <picture class="colortrue rounded-circle">
                  <img src="../assets/icons/enviado.png" alt="" />
                </picture>
                <h6 class="fw-bold texttrue pt-2 mt-4">Pedido en Ruta</h6>
                <div class="line line-true d-md-none"></div>
              </div>
              <div class="position-relative mb-3 mb-md-0" v-else>
                <picture class="colorfalse rounded-circle">
                  <img src="../assets/icons/enviado.png" alt="" />
                </picture>
                <h6 class="fw-bold textfalse pt-2 mt-4">Pedido sin Enviar</h6>
                <div class="line line-false d-md-none"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { firebaseApp } from "../../firebaseConfig";

const db = getFirestore(firebaseApp);

export default {
  data() {
    return {
      id_order: "",
      accepted: "",
      enlisting: "",
      incomplete: "",
      prepared: "",
      prepared_without_packing: "",
      prepared_packed: "",
      invoiced: "",
      on_way: "",
      delivered: "",
      finalized: "",
      orderData: null,
    };
  },
  methods: {
    getOrder() {
      const docRef = doc(db, "orders", this.id_order.toString());
      onSnapshot(
        docRef,
        (docSnap) => {
          if (docSnap.exists()) {
            this.orderData = docSnap.data();

            this.accepted = docSnap.data().status.accepted;
            this.enlisting = docSnap.data().status.enlisting;
            this.incomplete = docSnap.data().status.incomplete;
            this.prepared = docSnap.data().status.prepared;
            this.prepared_without_packing =
              docSnap.data().status.prepared_without_packing;
            this.prepared_packed = docSnap.data().status.prepared_packed;
            this.invoiced = docSnap.data().status.invoiced;
            this.on_way = docSnap.data().status.on_way;
            this.delivered = docSnap.data().status.delivered;
            this.finalized = docSnap.data().status.finalized;
          } else {
            alert("No existe la orden");
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.body {
  background: #f8f8f8;
}
.colortrue {
  background: #e81d84;
}
.colorfalse {
  background: #cecbcb;
}

.texttrue {
  color: #e81d84;
}
.textfalse {
  color: #cecbcb;
}
picture.rounded-circle {
  width: 125px;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
}

.line {
  height: 3px;
  position: absolute;
  width: 35px;
  right: 45%;
  top: 72%;
  z-index: 1;
}

@media (max-width: 767px) {
  .line {
    transform: rotate(90deg);
  }
}

@media (min-width: 768px) {
  .line {
    width: 65%;
    right: -40%;
    top: 33%;
  }
}

.line-true {
  background: #e81d84;
}

.line-false {
  background: #cecbcb;
}
</style>
