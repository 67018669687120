<template>
  <div id="app">
    <OrdersView />
  </div>
</template>

<script>
import OrdersView from "./components/OrdersView.vue";

export default {
  name: "App",
  components: {
    OrdersView,
  },
};
</script>

<style>
#app {
  font-family: "Jost", sans-serif;
}
</style>
